import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-graph-filter',
  templateUrl: './graph-filter.component.html',
  styleUrls: ['./graph-filter.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    NgIf,
  ],
})
export class GraphFilterComponent {
  /**
   * Emits number of months the filter is set for
   */
  @Output() filterChange = new EventEmitter<number>();
  months$: Observable<number> = this.filterChange.asObservable();

  /**
   * Whether to include the five year option.
   */
  @Input() showFiveYear = false;

  constructor() {
    // Kick this to the next cycle so our view has subscribed to the months$ observable.  Can't find a lifecycle event that prevents this
    // timeout.
    setTimeout(() => this.filterChange.emit(3), 5);
  }
}
